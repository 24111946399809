<template>
  <div class="main">
    <div class="div">123</div>
    <input type="checkbox" id="checkbox1" /><label for="checkbox1">123</label>
    <input type="checkbox" id="checkbox2" /><label for="checkbox2">23</label>
    <p>您的性别：</p>
    <div class="radio-sex">
      <input type="radio" id="sex1" name="sex" />
      <label for="sex1"></label>
      <span>男</span>
    </div>
    <div class="radio-sex">
      <input type="radio" id="sex2" name="sex" />
      <label for="sex2"></label>女
    </div>
    <p>喜欢的颜色：</p>
    <div class="radio-label">
      <input type="radio" id="color1" name="color" />
      <label for="color1">蓝色</label>
    </div>
    <div class="radio-label">
      <input type="radio" id="color2" name="color" />
      <label for="color2">红色22</label>
    </div>
    <div class="radio-label">
      <input type="radio" id="color3" name="color" />
      <label for="color3">绿色333</label>
    </div>
    <span class="iconfont" style="color: red"></span>
    <p>喜欢的数字：</p>
    <div class="radio-check">
      <input
        type="radio"
        id="num1"
        name="num"
        :checked="num == 'num1'"
        @click="num = 'num1'"
      />
      <label for="num1">一1</label>
      <span class="iconfont iconq"></span>
    </div>
    <div class="radio-check">
      <input
        type="radio"
        id="num2"
        name="num"
        :checked="num == 'num2'"
        @click="num = 'num2'"
      />
      <label for="num2">二222222</label>
      <span class="iconfont iconq"></span>
    </div>
    <div @click="write()">点我输出你喜欢的数字：{{ num }}</div>
  </div>
</template>
<script>
export default {
  name: "input-component",
  data() {
    return {
      num: "num1",
    };
  },
  methods: {
    write() {
      console.log(this.num);
    },
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "iconfont"; /* project id 1313406 */
  src: url("//at.alicdn.com/t/font_1313406_8rrf66s2gx.eot");
  src: url("//at.alicdn.com/t/font_1313406_8rrf66s2gx.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_1313406_8rrf66s2gx.woff2") format("woff2"),
    url("//at.alicdn.com/t/font_1313406_8rrf66s2gx.woff") format("woff"),
    url("//at.alicdn.com/t/font_1313406_8rrf66s2gx.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_1313406_8rrf66s2gx.svg#iconfont") format("svg");
}
.main {
  padding: 20px;
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin: 15px 0;
}
.div:before {
  content: url(http://www.w3school.com.cn/i/w3school_logo_white.gif);
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  background-color: #fff;
  -webkit-appearance: none;
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  outline: none;
}
input[type="checkbox"]:checked {
  background: url("https://gss1.bdstatic.com/9vo3dSag_xI4khGkpoWK1HF6hhy/baike/c0%3Dbaike92%2C5%2C5%2C92%2C30/sign=5bc58607708b4710da22f59ea2a7a898/622762d0f703918f418843ec533d269759eec4bf.jpg")
    no-repeat center;
  background-size: 100% 100%;
}
.radio-sex {
  position: relative;
  display: inline-block;
  margin-right: 12px;
}

.radio-sex input {
  vertical-align: middle;
  /* 前面三行代码是为了让radio单选按钮与文字对齐 */
  width: 20px;
  height: 20px;
  appearance: none; /*清楚默认样式*/
  -webkit-appearance: none;
  opacity: 0;
  outline: none;
  /* 注意不能设置为display:none*/
}

.radio-sex label {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  /*注意层级关系，如果不把label层级设为最低，会遮挡住input而不能单选*/
  width: 20px;
  height: 20px;
  border: 1px solid #3582e9;
  border-radius: 100%;
}

.radio-sex input[type="radio"]:checked + label {
  background: #3582e9;
}

.radio-sex input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
}
.radio-sex span {
  vertical-align: middle;
}
/* 喜欢的颜色 */
.radio-label,
.radio-check {
  display: inline-block;
  position: relative;
}
.radio-label input[type="radio"] {
  appearance: none; /*清楚默认样式*/
  -webkit-appearance: none;
  /* opacity: 0; */
  outline: none;
  position: absolute;
  z-index: 2;
  width: 6px;
  height: 6px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: #b4b4b4;
}
.radio-label label {
  display: inline-block;
  min-width: 50px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding-right: 10px;
  border-radius: 5px;
  padding-left: 25px;
  color: #fff;
  background-color: #c9c9c9;
}
.radio-label input[type="radio"]:checked {
  opacity: 0;
}
.radio-label input[type="radio"]:checked + label {
  color: #fff;
  background-color: #3597db;
}
.radio-label input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  left: 12px;
  top: 5px;
  width: 5px;
  height: 12px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
}
.iconq {
  font-size: 25px;
  position: absolute;
  right: -11px;
  bottom: -11px;
  z-index: 1;
  opacity: 0;
}

/* 喜欢的数字 */
.radio-check input[type="radio"] {
  appearance: none; /*清楚默认样式*/
  -webkit-appearance: none;
  /* opacity: 0; */
  outline: none;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: #b4b4b4;
}
.radio-check input[type="radio"] {
  opacity: 0;
}
.radio-check label {
  display: inline-block;
  /* min-width: 50px; */
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding-right: 10px;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  padding-left: 10px;
  cursor: pointer;
}
.radio-check input[type="radio"]:checked + label + span {
  color: #3597db;
  opacity: 1;
}
.radio-check input[type="radio"]:checked + label {
  border-color: #3597db;
}
.radio-check input[type="radio"]:hover + label {
  border-color: #3597db;
}
</style>
